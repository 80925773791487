<template>
  <div :id="module.id">
    <ClientOnly>
      <template #fallback>
        <CRSearchLoadingPlaceholder :job-search-global="module" used-in-module />
      </template>

      <AisInstantSearch
        :search-client="searchClient"
        :index-name="indexName"
        :insights="{
          insightsClient: null,
          onEvent: (event: any) => {
            handleTrackInsightsEvent(event);
          },

        }"
      >
        <CRSearchAndFilterModule :computed-function="computed" :module="module" @filter-clicked="handleTrackFilterOpened($event)">
          <template #configure>
            <AisConfigure
              :hits-per-page.camel="10"
              :query="queryPreset"
              :filters="filtersPreset"
            />
          </template>

          <template #search-box>
            <AisSearchBox
              :placeholder="module.config.search.placeholderText"
              @input="handleTrackSearchInput"
            />
          </template>

          <template #filter-list="{ filter }">
            <CRListFilterWrapper :filter-key="filter.key">
              <AisRefinementList :attribute="filter.key" searchable :limit="1000" :sort-by="listSorting">
                <template
                  #default="{
                    items,
                    isFromSearch,
                    refine,
                    createURL,
                    searchForItems,
                    sendEvent,
                  }"
                >
                  <CREndlessFilterList
                    :attribute="filter.key"
                    :items="items"
                    :refine="refine"
                    :search-for-items="searchForItems"
                    :create-u-r-l="createURL"
                    :is-from-search="isFromSearch"
                    :send-event="sendEvent"
                    :job-search-global="module"
                  />
                </template>
              </AisRefinementList>
            </CRListFilterWrapper>
          </template>

          <template #filter-select="{ filter }">
            <AisRefinementList
              :attribute="filter.key"
              :limit="1000"
              :class-names="{
                'ais-RefinementList': 'search-filter-filter-select-list',
              }"
              :sort-by="listSorting"
            >
              <template #default="{ items, refine }">
                <button
                  v-for="item in items.sort((a, b) => a.value < b.value)"
                  :key="item.value"
                  class="select-button"
                  :class="item.isRefined ? '-active' : ''"
                  @click.prevent="refine(item.value)"
                >
                  {{ item.value }}
                </button>
              </template>
            </AisRefinementList>
          </template>

          <template #filter-slider="{ filter }">
            <AisRangeInput :attribute="filter.key">
              <template #default="{ currentRefinement, range, refine }">
                <div :ref="`slider-${filter.key}`" class="slider-container">
                  <Slider
                    :min="range.min"
                    :max="range.max"
                    :model-value="toValue(currentRefinement, range)"
                    :tooltips="true"
                    tooltip-position="bottom"
                    @update="refineRange(refine, $event)"
                    @slide="
                      setSliderTooltipOrientation($event, range, filter.key)
                    "
                  />
                </div>
              </template>
            </AisRangeInput>
          </template>

          <template #single-filter-reset="{ filter }">
            <AisClearRefinements :included-attributes="[filter.key]">
              <template #resetLabel>
                {{ module.config.filters.resetButtonsText.singleFilter }}
              </template>
            </AisClearRefinements>
          </template>

          <template #results-loading>
            <AisStateResults>
              <template #default="{ status }">
                <CRResultsLoading
                  v-show="status === 'loading'"
                  :job-search-global="module"
                />
              </template>
            </AisStateResults>
          </template>

          <template #stats>
            <AisStats>
              <template #default="{ nbHits }">
                <CRSearchStats ref="searchStats" :number-of-hits="nbHits" :stats-text="module.config.results.amountHitsText" :total-count="totalOffersCount" />
              </template>
            </AisStats>
          </template>

          <template #sorting-select>
            <AisSortBy :items="module.config.sorting.items" />
          </template>

          <template #all-filter-reset>
            <AisClearRefinements>
              <template #default="{ canRefine, refine }">
                <CRResetFiltersButton
                  :label="
                    module.config.filters.resetButtonsText.allFilters
                  "
                  :disabled="!canRefine"
                  @click.prevent="refine"
                  @mousedown="handleTrackClickRemoveAllFilter"
                />
              </template>
            </AisClearRefinements>
          </template>

          <template #results>
            <AisStateResults>
              <template #default="{ results: { hits } }">
                <AisHits>
                  <template #item="{ item }">
                    <CRResultItem :item="item" :job-search-global="module" />
                  </template>
                </AisHits>

                <AisPagination>
                  <template
                    #default="{
                      currentRefinement,
                      nbPages,
                      isFirstPage,
                      isLastPage,
                      refine,
                    }"
                  >
                    <CRResultsPagination
                      v-show="hits.length"
                      :pagination-config="module.config.results.pagination"
                      :current-page="currentRefinement"
                      :number-of-pages="nbPages"
                      :is-first-page="isFirstPage"
                      :is-last-page="isLastPage"
                      :change-page-function="refine"
                      scroll-target=".search-module-section"
                      @page-changed="handleTrackPaginationChange($event)"
                    />
                  </template>
                </AisPagination>
              </template>
            </AisStateResults>
          </template>

          <template #empty-state>
            <AisStateResults>
              <template #default="{ results: { hits } }">
                <CRResultsEmptyState
                  v-show="hits.length === 0"
                  :headline="module.config.results.emptyState.headline"
                  :subline="module.config.results.emptyState.subline"
                />
              </template>
            </AisStateResults>
          </template>

          <template #map-view>
            <AisConfigure :hits-per-page.camel="250" />
            <CRSearchMapContainer
              :job-search-global="module"
              :tiles-url="mapTilesUrl"
              :cdn-url="cdnUrl"
              :map-language="jobShopLocale.split('-')[0]"
            >
              <CRSearchMapVue3 />
            </CRSearchMapContainer>
          </template>
        </CRSearchAndFilterModule>
      </AisInstantSearch>
    </ClientOnly>
  </div>
</template>

<script lang="ts">
import {
  AisClearRefinements,
  AisConfigure,
  AisHits,
  AisInstantSearch,
  AisPagination,
  AisRangeInput,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStateResults,
  AisStats,
} from "vue-instantsearch/vue3/es"
import Slider from "@vueform/slider"
import { searchAndFilterMethods } from "@jobshop/crossroads/components/SearchAndFilter/mixins/searchAndFilter"
import { searchAndFilterModuleComputed } from "@jobshop/crossroads/components/SearchAndFilter/mixins/searchAndFilterModule"
import { debounce } from "radash"
import { useTypesenseSearchClient } from "~/composables/useTypesenseSearchClient"
import { InsightsEvents } from "~/utils/enums/InsightsEvents"
import { trackSearchResultsPageChanged } from "~/composables/TrackEvents"

const indexName = "offers"

export default defineNuxtComponent({
  components: {
    AisClearRefinements,
    AisHits,
    AisInstantSearch,
    AisRangeInput,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStateResults,
    AisStats,
    AisPagination,
    Slider,
    AisConfigure,
  },
  mixins: [searchAndFilterMethods, searchAndFilterModuleComputed],
  props: {
    module: {
      type: Object as PropType<ContentModule>,
      required: true,
    },
  },
  data() {
    return {
      totalOffersCount: 0,
      filtersPreset: "",
      queryPreset: "",
    }
  },
  async setup(props) {
    const { data: jobShop } = useNuxtData("jobShopData")

    // Get typesense search client
    const { searchClient } = await useTypesenseSearchClient(props.module.config)

    // Provide locale & cdn urls for map
    const jobShopLocale = ref(jobShop.value?.locale)
    const { public: { cdnUrl, mapTilesUrl } } = useRuntimeConfig()

    // Track loaded filters
    // Only possible on client, since amplitude is a client-side plugin
    if (import.meta.client) {
      props.module.config.filters.options.forEach((filter: FilterOption) => {
        if (filter.active)
          trackFilterLoaded(filter.key)
      })
    }

    // Use ref on CRSearchStats to track number of results
    const searchStats = ref(null)
    watchEffect(() => {
      if (searchStats.value)
        trackLoadSearchResults(searchStats.value.numberOfHits)
    })

    // Define sorting for list and select filters
    const listSorting = ref(["isRefined", "name:asc"])

    return {
      searchClient,
      jobShopLocale,
      cdnUrl,
      mapTilesUrl,
      searchStats,
      indexName,
      listSorting,
    }
  },
  async mounted() {
    await this.fetchTotalOffersCount()
    this.setPresets()
  },
  methods: {
    setPresets() {
      this.queryPreset = this.module.config.search.preset
      this.filtersPreset = this.resolvedPresetFilters
    },
    async fetchTotalOffersCount() {
      const response = await this.searchClient.search([
        {
          indexName: "offers",
          params: {
            query: this.module.config.search.preset,
            filters: this.resolvedPresetFilters,
          },
        },
      ])

      this.totalOffersCount = response.results[0].nbHits
    },
    handleTrackSearchInput: debounce({ delay: 1000 }, (event) => {
      const searchRequest = event.target.value

      if (searchRequest)
        trackSearchApplied(searchRequest)
    }),
    handleTrackFilterOpened(event: string) {
      trackFilterOpened(event)
    },
    handleTrackClickRemoveAllFilter() {
      trackClickRemoveAllFilter()
    },
    handleTrackViewSearchResult(item: any, itemIndex: number) {
      trackViewSearchResult(item.id, item.title, itemIndex + 1)
    },
    handleTrackInsightsEvent(event: any) {
      switch (event.insightsMethod) {
        case InsightsEvents.CLICKED_FILTERS:
          trackFilterSelected(event.attribute, event.payload.filters[0].split(":")[1])
          break
        case InsightsEvents.CLICKED_RESULTS:
          trackClickSearchResult(event.hits[0].id, event.hits[0].title, event.payload.positions[0])
          break
      }
    },
    handleTrackPaginationChange(changeType: string) {
      trackSearchResultsPageChanged(changeType)
    },
  },
})
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style src="@jobshop/crossroads/assets/scss/search-filter/_index.scss"></style>
