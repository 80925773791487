import { nanoid } from "nanoid"
import Logger from "@/utils/Logger"

export async function useContentModules(pageId: string) {
  const { $api } = useNuxtApp()
  const { t } = useI18n()
  const { tenantId, jobShopId, acceptLanguage } = useHeaderStore()
  const { dataCacheTtl } = useRuntimeConfig()

  const requestId = nanoid()
  const contextString = `content modules for pageId: "${pageId}", tenantId: "${tenantId}" and jobShopId: "${jobShopId}"`

  let startTime
  if (import.meta.server) {
    Logger(requestId).start(`Start fetching ${contextString}`)
    startTime = Date.now()
  }

  // Using the useCachedAsyncData composable from nuxt-multi-cache here and adding relevant tags for cache invalidation
  const {
    data: contentModules,
    status: contentModulesStatus,
    error: contentModulesError,
  } = await useCachedAsyncData(`contentModules-${pageId}`, () =>
    $api.content.getContentModules(
      tenantId,
      jobShopId,
      pageId,
      acceptLanguage,
    ), {
    serverMaxAge: dataCacheTtl,
    serverCacheTags: [
      `tenantId:${tenantId}`,
      `jobShopId:${jobShopId}`,
      `pageId:${pageId}`,
    ],
  })

  if (import.meta.server) {
    if (!contentModulesError.value) {
      const duration = Date.now() - startTime
      Logger(requestId).success(`Successfully fetched ${contextString}. (${duration}ms)`)
    }
    else {
      const errorMessage = contentModulesError.value?.data?.errors[0]?.message || contentModulesError.value?.data?.description || "Unknown error occurred"
      Logger(requestId).error(`Error while fetching ${contextString}: ${errorMessage}\n\n`, contentModulesError.value)
    }
  }

  if (contentModulesError.value) {
    throw createError({
      statusCode: contentModulesError.value.statusCode,
      statusMessage: t("error-messages.content-modules-error"),
    })
  }

  return { contentModules, contentModulesStatus, contentModulesError }
}
