import { nanoid } from "nanoid"
import Logger from "@/utils/Logger"

export async function usePageData(pageSlug: string) {
  const { $api } = useNuxtApp()
  const { t } = useI18n()
  const { tenantId, jobShopId, acceptLanguage } = useHeaderStore()
  const { dataCacheTtl } = useRuntimeConfig()

  const requestId = nanoid()
  const contextString = `page data for pageSlug: "${pageSlug}", tenantId: "${tenantId}" and jobShopId: "${jobShopId}"`

  let startTime
  if (import.meta.server) {
    Logger(requestId).start(`Start fetching ${contextString}`)
    startTime = Date.now()
  }

  // Using the useCachedAsyncData composable from nuxt-multi-cache here and adding relevant tags for cache invalidation
  const {
    data: page,
    status: pageStatus,
    error: pageError,
  } = await useCachedAsyncData(`pageData-${pageSlug}`, () => $api.page.getPageBySlug(tenantId, jobShopId, acceptLanguage, pageSlug), {
    serverMaxAge: dataCacheTtl,
    serverCacheTags: (page) => {
      return [
      `tenantId:${tenantId}`,
      `jobShopId:${jobShopId}`,
      `pageId:${page.id}`,
      ]
    },
  })

  if (import.meta.server) {
    if (!pageError.value) {
      const duration = Date.now() - startTime
      Logger(requestId).success(`Successfully fetched ${contextString}. (${duration}ms)`)
    }
    else {
      const errorMessage = pageError.value?.data?.errors[0]?.message || pageError.value?.data?.description || "Unknown error occurred"
      Logger(requestId).error(`Error while fetching ${contextString}: ${errorMessage}\n\n`, pageError.value)
    }
  }

  if (pageError.value) {
    if (pageError.value.statusCode === 404) {
      throw createError({
        statusCode: 404,
        statusMessage: t("error-messages.page-not-found"),
      })
    }
    else {
      throw createError({
        statusCode: pageError.value.statusCode,
        statusMessage: t("error-messages.general"),
      })
    }
  }

  return { page, pageStatus, pageError }
}
